import {API} from '@aws-amplify/api';


export class BaseService {

    apiName: string
    collectionPath: string

    constructor(apiName: string, collectionPath: string) {
        this.apiName = apiName;
        this.collectionPath = collectionPath;
    }


    async fetchRecords(customQuery?: { [key: string]: any }) {

        const query = Object.assign(
            {},
            customQuery ? customQuery : {}
        );

        const path = this.collectionPath;

        if (typeof query.page !== 'undefined') {
            //query.offset = (query.page - 1) * query.limit;

            if (query.page === 1) {
                query.offset = 0
            } else {
                query.offset = (query.page - 1) * query.limit;
            }

            delete query.page;
        }

        const options = {
            queryStringParameters: query,
            response: true,
        };

        return this.makeGetApiCall(this.apiName, path, options);
    }

    async fetch(recordId: string, responseGroup = 'large') {
        const path = [this.collectionPath, recordId].join('/');

        const query = {'response-group': responseGroup};


        const options = {
            queryStringParameters: query,
            response: true,
        };

        return this.makeGetApiCall(this.apiName, path, options);
    }

    async add(payload: object) {
        const path = this.collectionPath;

        const headers = {
            'Content-Type': 'application/json',
        };

        const options = {
            body: payload,
            headers: headers,
            response: true,
        };

        const response = await this.makePostApiCall(this.apiName, path, options);


        if (response.headers.location) {
            return this.makeGetApiCall(
                this.apiName,
                response.headers.location,
                {
                    headers: headers,
                    response: true
                }
            );
        } else {
            return response;
        }
    }

    async update(recordId: string, payload: object) {
        const path = [this.collectionPath, recordId].join('/');

        const headers = {
            'Content-Type': 'application/json',
        };

        const options = {
            body: payload,
            headers: headers,
            response: true,
        };

        return this.makePutApiCall(this.apiName, path, options);
    }

    async patch(recordId: string, payload: object) {
        const path = [this.collectionPath, recordId].join('/');

        const headers = {
            'Content-Type': 'application/json',
        };

        const options = {
            body: payload,
            headers: headers,
            response: true,
        };

        return this.makePatchApiCall(this.apiName, path, options);
    }

    async deleteRecord(recordId: string) {
        const path = [this.collectionPath, recordId].join('/');

        const options = {response: true};

        return this.makeDeleteApiCall(this.apiName, path, options);
    }

    async makeGetApiCall(apiName: string, path: string, options: { [key: string]: any }) {
        console.log(`making GET call to ${apiName} api with path: ${path}`);
        console.log('GET call options', options);

        try {
            return await API.get(apiName, path, options);
        } catch (e: unknown | any) {

            if (typeof e === 'object' && Object.hasOwn(e, 'response') && e.response.status === 302) {
                return e.response;
            }

            console.trace(e);
            // window.location.assign(
            //     config.platform.login + '?returnTo=' + config.platform.links
            // );
            if (typeof e === 'string') {
                throw new Error(e);
            } else if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    }

    async makePostApiCall(apiName: string, path: string, options: { [key: string]: any }) {
        console.log(`making POST call to ${apiName} api with path: ${path}`);
        console.log('POST call options', options);

        try {
            const response = await API.post(apiName, path, options);
            return response;
        } catch (e: unknown) {
            console.trace(e);
            if (typeof e === 'string') {
                throw new Error(e);
            } else if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    }

    async makePutApiCall(apiName: string, path: string, options: { [key: string]: any }) {
        console.log(`making PUT call to ${apiName} api with path: ${path}`);
        console.log('PUT call otions', options);

        try {
            return await API.put(apiName, path, options);
        } catch (e: unknown) {
            console.trace(e);
            if (typeof e === 'string') {
                throw new Error(e);
            } else if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    }

    async makePatchApiCall(apiName: string, path: string, options: { [key: string]: any }) {
        console.log(`making PATCH call to ${apiName} api with path: ${path}`);
        console.log('PATCH call otions', options);

        try {
            return await API.patch(apiName, path, options);
        } catch (e: unknown) {
            console.trace(e);
            if (typeof e === 'string') {
                throw new Error(e);
            } else if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    }

    async makeDeleteApiCall(apiName: string, path: string, options: { [key: string]: any }) {
        console.log(`making DELETE call to ${apiName} api with path: ${path}`);
        console.log('DELETE call otions', options);

        try {
            return await API.del(apiName, path, options);
        } catch (e: unknown) {
            console.trace(e);
            if (typeof e === 'string') {
                throw new Error(e);
            } else if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    }
}

export default BaseService;
