import {Outlet} from 'react-router-dom';

import {
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderToolbar,
} from '@pac/platform-ui-components';

import {
    AppShell,
    Box,
    Card,
    Center,
    Container,
    ScrollArea,
} from '@mantine/core';
import PanasonicLogo from "../assets/images/panasonic-logo.png";
import {PageFooter} from "./PageFooter.tsx";

import classes from './AppLayout.module.css';
import {useViewportSize} from '@mantine/hooks';
import {ColorSchemeIcon} from "../components/ColorSchemeIcon.tsx";


export const AppLayout = () => {
    const {
        height,
    } = useViewportSize();

    const headerHeight = 70;
    const footerHeight = 95;

    const toolbar = <PlatformHeaderToolbar
        tools={[<ColorSchemeIcon/>]}/>;

    const header = <PlatformHeader toolbar={toolbar}/>;

    return (
        <AppShell
            header={{height: headerHeight}}
            footer={{height: footerHeight}}
        >
            <AppShell.Header>
                {header}
            </AppShell.Header>

            <AppShell.Main>
                <Box className={classes.main} style={{height: height - headerHeight - footerHeight}}>
                    <ScrollArea h={'100%'}>
                        <Container className={classes.mainContainer}>
                            <Card>
                                <Card.Section>
                                    <Center className={classes.pageHeader}>
                                        <img
                                            src={PanasonicLogo}
                                            alt="Panasonic Avionics"
                                            className="logo"
                                        />
                                    </Center>
                                </Card.Section>

                                <Box className={classes.pageContent}>
                                    <Outlet/>
                                </Box>

                                <Card.Section className={classes.pageFooter}>
                                    <PageFooter/>
                                </Card.Section>
                            </Card>

                        </Container>
                    </ScrollArea>
                </Box>
            </AppShell.Main>

            <AppShell.Footer p={'md'}>
                <PlatformFooter/>
            </AppShell.Footer>

        </AppShell>
    );
};
