import {
    ReactNode,
    useEffect
} from 'react';
import {useAppSelector} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {Application} from "../types.ts";
import {activeApplicationSelector} from "../selectors.ts";
import {moduleConfig} from "../config";
import {ApplicationsContext} from '../context/ApplicationsContext.tsx';
import {useApplication} from "../hooks/useApplication.ts";
import {useApplications} from "../hooks/useApplications.ts";


export const ApplicationsContextProvider = ({children}: { children: ReactNode }) => {
    const {handleSaveApplication} = useApplication()
    const {
        handleDeleteApplicationsWithNoVersionProperty,
        handleFetchApplications
    } = useApplications();


    const doSaveApplication = (url: URL, returnToSearch: string, isDefault = false) => {


        const application = {
            id: crypto.randomUUID(),
            version: moduleConfig.schemaVersion,
            returnToSearch: returnToSearch,
            host: url.host,
            hostname: url.hostname,
            origin: url.origin,
            pathname: url.pathname,
            port: url.port,
            protocol: url.protocol,
            isDefault: isDefault,
            isActive: true
        } as Application;

        handleSaveApplication(application);


    }

    // current looks like this - https://login.nextcloud.aero?returnTo=https://myapp.nexcloud.aero/my-path?foo=bar&zet=zero
    const current = new URL(window.location.href);

    const params = new URLSearchParams(current.search);

    // location search looks like this - ?returnTo=https://myapp.nexcloud.aero/my-path?foo=bar&zet=zero;
    const locationSearch = window.location.search;
    // console.debug('location search', locationSearch);

    useEffect(() => {
        handleDeleteApplicationsWithNoVersionProperty();
    }, []);


    useEffect(() => {
        // we have returnTo query parameter
        if (params.has('returnTo')) {

            const returnToUrl = params.get('returnTo');
            const url = new URL(returnToUrl as string);

            // delimiter looks like this - ?returnTo=https://myapp.nexcloud.aero/my-path;
            const delimiter = '?returnTo=' + url.origin + url.pathname;
            // console.debug('delimiter', delimiter);

            // returnToSearch looks like this - ?foo=bar&zet=zero;
            let returnToSearch = locationSearch.substring(delimiter.length);
            // console.debug('returnToSearch', returnToSearch);

            if (returnToSearch.charAt(0) === '?') {
                returnToSearch = returnToSearch.substring(1);
            }

            doSaveApplication(url, returnToSearch, false);
        }
    }, [params]);

    useEffect(() => {
        if (!params.has('returnTo')) {
            setTimeout(() => {
                handleFetchApplications();
            }, 1000);
        }
    }, []);


    const activeApplication = useAppSelector((state: RootState) =>
                                                 activeApplicationSelector(state)
    );

    return (
        <ApplicationsContext.Provider value={{activeApplication}}>
            {children}
        </ApplicationsContext.Provider>
    );
};

