import Auth from './auth.ts';
import API from './api.ts';
import Analytics from './analytics.ts';
import Storage from './storage.ts';


let config = {
    Auth: Auth,
    API: API,
    Analytics: Analytics,
    Cache: {
        defaultTTL: 2592000000, // 30 days
    },
    Storage: Storage,
};

export default config;
